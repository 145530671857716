<template>
  <percobaan-dropdown />
</template>

<script>
  import PercobaanDropdown from '../components/PercobaanDropdown'

  export default {
    name: 'PercobaanView',

    components: {
      PercobaanDropdown,
    },
  }
</script>
