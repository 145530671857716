<template>
  <jadwal-pelajaran />
</template>

<script>
  import JadwalPelajaran from '../components/JadwalPelajaran'

  export default {
    name: 'HomeView',

    components: {
      JadwalPelajaran,
    },
  }
</script>
